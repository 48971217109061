.App {
  font-family: sans-serif;
  text-align: center;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  /* border-radius: 10px; */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  background: #2f4454;
  /* border-radius: 5px; */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3f515f;
}

html {
  font-family: sans-serif;
  font-size: 14px;
}

table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}