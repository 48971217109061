.MuiTooltip-popper > div {
  color: #777;
  background-color: rgb(226, 226, 226);
  font-size: 1.2em;
  max-width: 200px;
  border-radius: 15px;
}

.MuiTooltip-popper > div > span {
  color: rgb(226, 226, 226);
}

span.MuiCheckbox-root > svg {
  color: #2f4454;
}

th,
td {
  border: 1px solid rgb(30, 30, 30) !important;
  max-width: 100%;
  white-space: nowrap;
  padding: 0.75rem;
}

#cell-title {
  justify-content: center;
}

#cell-title > span {
  display: inline-flex;
  align-items: center;
}
